export interface AnnotationAgreementState {
    metricLevel: MetricLevel;
}

export function AnnotationAgreementStateFactory(data?: Partial<AnnotationAgreementState>): AnnotationAgreementState {
    return {
        metricLevel: 'area',
        ...data,
    };
}

export interface IAnnotationMetricsResponse {
    data: AnnotationMetricsAvgData[];
    comparison: Array<[string, string]>;
    studyIdList: string[];
}

export interface AnnotationMetricsAvgData {
    label: string;
    study_id?: string;
    image_id?: string;
    image_index?: number;
    series_id?: string;
    comparison: Dictionary<IComparison>;
    mean_area?: number;
}

export interface AnnotationMetricsAvgRequestPayload {
    annotation_id: string;
    avg_type: AvgType;
    comparison_type: ComparisonType;
    study_id?: string;
    user_id?: string;
    level?: 'voxel' | 'area';
}

export interface VoxelListRequestPayload {
    annotation_id: string;
    comparison_type: ComparisonType;
}

export interface VoxelListResponse {
    data: VoxelListData[];
    studyIdList: string[];
    userList: { email: string; displayName: string; _id: string }[];
    projectList: { annotation_id: string; annotation_name: string }[];
}

export interface VoxelListData {
    label: string;
    study_id: string;
    series_id: string;
    voxel_value: Dictionary<number>;
}

export interface IComparison {
    match: string;
    dice: number;
    mean_area?: number;
}

export type ComparisonType = 'project' | 'user';
export type AvgType = 'study' | 'label' | 'all';

export type MetricLevel = 'volume' | 'area';
